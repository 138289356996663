.App {
    text-align: center;
    min-height: 100vh;
  }
  
  .App-logo {
    width: 100px;
    pointer-events: none;
  }
  
  .App-header {
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
    margin-top: 20px;
  }
  